<template>
    <div class="mainDiv">
        <Navbar :user="true" />
        <br><br><br><br><br>
        <div class="container text-center mb-5">
            <div class="row rowCenter">
                <h2 class="name">Bienvenid@ {{ localUser }}</h2>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-md-12">
                    <router-link class="glyphicon glyphicon-lock menuIcon" :to="`/user/usrAdmin/edit/${ localIdEnc }`"></router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 text-right center-resp">
                    <router-link class="btn btn-default btn-sm mt-3 width-resp" :to="`/user/usrAdmin/edit/${ localIdEnc }`">Actualizar información</router-link>
                </div>
                <div class="col-md-6 text-left center-resp">
                    <router-link class="btn btn-default btn-sm mt-3 width-resp" :to="`/user/usrAdmin/psw/${ localIdEnc }`">Cambiar contraseña</router-link>
                </div>
            </div>
            <hr class="red mt-5" />
            <div class="row">
                <div class="col-12">
                    <div class="card bg-light mb-3 card-primary">
                        
                        
                        <!--TIPO 1 Administrador del Sistema -->
                        <div class="card-header"><h5>Menú de administración de la plataforma</h5></div>
                        <div class="card-body" v-if="localTipo === 1">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-user menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body" style="text-align:left;">
                                                    <h5>Administración de usuarios</h5>
                                                    <router-link to="/user/usrAdmin/add" class="btn btn-sm form-control" style="text-align:left;">Crear nuevo usuario</router-link>
                                                    <router-link to="/user/usrAdmin" class="btn btn-sm form-control" style="text-align:left;">Administrar usuarios</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-cog menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body">
                                                    <h5 style="text-align:left;">Administración del contenido de la plataforma</h5>
                                                    <router-link to="/menupagina/menupaginaAdmin" class="btn btn-sm form-control" style="text-align:left;">Mantenimiento de los textos del menú de la página</router-link>
                                                    <router-link to="/principal/principalAdmin" class="btn btn-sm form-control" style="text-align:left;">Mantenimiento de los textos de la Página de inicio</router-link>
                                                    <router-link to="/acercade/acercadeAdmin" class="btn btn-sm form-control" style="text-align:left;">Mantenimiento de los textos del módulo Acerca de</router-link>
                                                    <router-link to="/servicioresp/serviciorespAdmin" class="btn btn-sm form-control" style="text-align:left;">Mantenimiento de los textos del módulo Directorio de servicios</router-link>
                                                    <!--<router-link :to=rl_int class="btn btn-sm form-control mb-4" style="text-align:left;">Mantenimiento de los textos de la Página de inicio<br> </router-link>-->
                                                    <!-- <router-link :to=rl_pp class="btn btn-sm form-control mb-4" style="text-align:left;">Mantenimiento de los textos del módulo Acerca de<br> </router-link>-->
                                                    <!-- <router-link :to=rl_sr class="btn btn-sm form-control mb-4" style="text-align:left;">Mantenimiento de los textos del módulo Directorio de servicios<br> </router-link> -->
                                                    <router-link :to=rl_cd class="btn btn-sm form-control mb-4" style="text-align:left;">Mantenimiento de los textos del módulo Conoce tus derechos<br> </router-link>
                                                    <router-link to="/conocederechosdoctos/conocederechosdoctosAdmin" class="btn btn-sm form-control" style="text-align:left;">Mantenimiento de publicaciones en el apartado de Conoce tus derechos</router-link>
                                                    <router-link to="/org_logos/org_logosAdmin" class="btn btn-sm form-control" style="text-align:left;">Mantenimiento de logotipos de Organizaciones</router-link>
                                                    <router-link to="/registroinf/registroinfAdmin" class="btn btn-sm form-control" style="text-align:left;">Mantenimiento de los módulos de registro de información</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                               
                            </div>
                        </div>

                        <div class="card-body" v-if="localTipo === 1 || localTipo === 2">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-pencil menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body" style="text-align:left;">
                                                    <h5>Registro de información</h5>
                                                    <a style=" text-decoration: none; margin-left: 10px; font-size:medium;" :href="url_reg_evento_viol" target="_blank" class="link1" ><small class="text-muted">Registro de casos de violencia</small></a><br>
                                                    <a style=" text-decoration: none; margin-left: 10px; font-size:medium;" :href="url_reg_serv_resp" target="_blank" class="link1" ><small class="text-muted">Registro del directorio de servicios</small></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="card-body" v-if="localTipo === 1 || localTipo === 3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-folder-open menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body" style="text-align:left;">
                                                    <h5>Consulta de comentarios a la página</h5>
                                                    <a style="color: #484848;font-size: small; text-decoration: none;" :href="`${ ubApi }/exp_comentarios_pagina.php`" target="_blank" title="En formato Excel">&ensp; Descargar los comentarios de la página</a>
                                                </div>
                                                    
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="card-body" v-if="localTipo === 1 || localTipo === 2 || localTipo === 3">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card mb-4">
                                        <div class="row no-gutters">
                                            <div class="col-lg-3 pt-4">
                                                <i class="glyphicon glyphicon-off menuIcon"></i>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="card-body" style="text-align:left;">
                                                    <h5>Salir del Sistema</h5>
                                                    <router-link :to=rl_logout class="btn btn-sm form-control mb-4" style="text-align:left;">Salir del sistema<br> </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Common/Navbar'
import getCatRegistroInfById from '@/helpers/getCatRegistroInfById'
//import validateTkn from '@/api/validateToken'
import { ubApi } from '@/api/baseUrl'
import charsService from '@/services/CharsService';

export default {
    components: {
        Navbar
    },
    data() {
        return {
            ubApi: ubApi.value,
            //url_reg_evento_viol:'eeee',
            //url_reg_serv_resp:'dddd',
            localUser: charsService.decode(localStorage.getItem('nombre'), 'string'),
            localId: charsService.decode(localStorage.getItem('id')),
            localIdEnc: localStorage.getItem('id'),
            localTipo: parseInt(charsService.decode(localStorage.getItem('tipo_usuario'))),
           // rl_pp: "/acercade/acercadeAdmin/edit/" + charsService.encode(1),
          //  rl_sr: "/servicioresp/serviciorespAdmin/edit/" + charsService.encode(1),
            rl_cd: "/conocederechos/conocederechosAdmin/edt/" + charsService.encode(1),
           // rl_qep: "/projuventud/projuventudAdmin/edit/" + charsService.encode(1),
            //rl_ppj: "/presupuesto/presupuestoAdmin/edit/" + charsService.encode(1), 
            //rl_int: "/principal/principalAdm/edit/" + charsService.encode(1),           
            //rl_pg: "/param_gen/param_genAdmin/edit/" + charsService.encode(1),
            rl_logout: "/logout"
            


        }
    },
    methods: {
        //validation() {
           // validateTkn()
        //},
        /*
        async getCat_Servicioresp() {
            const datos = await getCatRegistroInfById(  localStorage.getItem("Nidioma") )
            this.url_reg_evento_viol = datos.url_reg_evento_viol
            this.url_reg_serv_resp = datos.url_reg_serv_resp
            //alert(this.url_reg_evento_viol)
        },
        */
    },
    //beforeMount() {
    //    this.validation()
    //    this.url_reg_evento_viol='eeee'
    //},
    async mounted() {
        if (!this.localId)
            this.$router.push('/login')
        //this.$gtag.event('Sección administrativa');    //Registra el Evento en Google Analytics con nombre
        //await this.getCat_Servicioresp();
    },
}
</script>

<style>
    .name {
        color: #542EA5;         /* antes 235B4E */
    }
    .rowCenter {
        display: block;
    }
    .iconUser {
        color: #6F7271;
        font-size: 70px;
    }
    .btn-circle.btn-xl {
        width: 100px;
        height: 100px;
        padding: 10px 16px;
        border-radius: 80px;
        border: 3px solid #6F7271;
        font-size: 24px;
        line-height: 1.33;
    }

    .row .no-gutters {
        margin: 0;
    }
    .card-primary {
        min-width: 330px;
    }

    .menuIcon {
        color: #AD00E8;         /* antes BC955C */
        font-size: 70px;
    }

    .mb-3 {
        margin-bottom: 15px;
    }


    a.sobre {
        color: #8B8B8C;
        font-size: 15px;
        text-decoration: none;
    }


</style>